import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { MethodologyData } from '../../../data/solutions-db';

export default function ExecutionMethodology() {
  return (
    <section className="pb-5">
      <Container>
        <Row>
          <h2 className="text-center mb-5">
            {MethodologyData[0].heading}
            <span className="infenox-text-highlight prtn-line" />
          </h2>
          {MethodologyData[0].data.map(({
            id, heading, image, text, image2,
          }) => (
            <Col className="box-col box-res" key={id}>
              <Row>
                <Col className="col-10">
                  <div className="execution-methodology-img"><img src={image} alt={heading} /></div>
                  <h4>{heading}</h4>
                  <p className="custom-text-justify">{text}</p>
                </Col>
                <Col className="col-2 execution-methodology-arrow"><img src={image2} alt="arrow" /></Col>
              </Row>
            </Col>
          ))}
        </Row>
      </Container>
    </section>
  );
}
